import { ReactNode } from 'react';
import {
  WarningAmberOutlined as WarningAmberOutlinedIcon,
  CheckCircleOutlined as CheckCircleOutlinedIcon,
  ErrorOutlineOutlined as ErrorOutlineOutlinedIcon,
} from '@mui/icons-material';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { PropsColors } from '@Base/theme';

export type ApartmentStatusTypes = 'NOT_DONE' | 'REJECTED' | 'DONE_AND_VERIFIED' | 'FIXED' | 'DONE' | 'CONTRACTOR_APPROVED' | 'CONTRACTOR_REJECTED';

export const ApartmentStatusColorConstants: Record<ApartmentStatusTypes, PropsColors> = {
  'NOT_DONE': undefined,
  'REJECTED': 'error',
  'DONE_AND_VERIFIED': undefined,
  'FIXED': 'warning',
  'DONE': 'success',
  'CONTRACTOR_APPROVED': 'info',
  'CONTRACTOR_REJECTED': 'error'
};

export const ApartmentStatusIconConstants: Record<ApartmentStatusTypes, ReactNode> = {
  'NOT_DONE': undefined,
  'REJECTED': (<WarningAmberOutlinedIcon color={ApartmentStatusColorConstants['REJECTED']} />),
  'DONE_AND_VERIFIED': (<CheckCircleOutlinedIcon color={ApartmentStatusColorConstants['DONE']} />),
  'FIXED': (<ErrorOutlineOutlinedIcon  color={ApartmentStatusColorConstants['FIXED']} />),
  'DONE': (<CheckCircleOutlinedIcon color={ApartmentStatusColorConstants['DONE']} />),
  'CONTRACTOR_APPROVED': (<CheckCircleOutlinedIcon color={ApartmentStatusColorConstants['CONTRACTOR_APPROVED']} />),
  'CONTRACTOR_REJECTED': (<WarningAmberOutlinedIcon color={ApartmentStatusColorConstants['CONTRACTOR_REJECTED']} />)
};


export type ApartmentItemStatusTypes = 'NOT_DONE' | 'FIXED' | 'DONE' | 'REJECTED' | 'DONE_AND_VERIFIED' | 'CONTRACTOR_APPROVED' | 'CONTRACTOR_REJECTED';

export const ApartmentItemStatusColorConstants: Record<ApartmentItemStatusTypes, PropsColors> = {
  'NOT_DONE': undefined,
  'FIXED': 'warning',
  'REJECTED': 'error',
  'DONE': 'warning',
  'DONE_AND_VERIFIED': 'success',
  'CONTRACTOR_APPROVED': 'info',
  'CONTRACTOR_REJECTED': 'error'
};

export const ApartmentItemStatusIconConstants: Record<ApartmentItemStatusTypes, ReactNode> = {
  'NOT_DONE': (<KeyboardArrowRightIcon />),
  'FIXED': (<ErrorOutlineOutlinedIcon color={ApartmentItemStatusColorConstants['FIXED']} />),
  'REJECTED': (<WarningAmberIcon color={ApartmentItemStatusColorConstants['REJECTED']} />),
  'DONE': (<ErrorOutlineOutlinedIcon color={ApartmentItemStatusColorConstants['DONE']} />),
  'DONE_AND_VERIFIED': (<CheckCircleOutlinedIcon color={ApartmentItemStatusColorConstants['DONE_AND_VERIFIED']} />),
  'CONTRACTOR_APPROVED': (<CheckCircleOutlinedIcon color={ApartmentItemStatusColorConstants['CONTRACTOR_APPROVED']} />),
  'CONTRACTOR_REJECTED': (<WarningAmberIcon color={ApartmentItemStatusColorConstants['CONTRACTOR_REJECTED']} />),
};
