import { useEffect, useState } from 'react';
import { Suspense } from 'react';
import type { FC } from 'react';
import { useRoutes } from 'react-router-dom';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import routes from '@Base/routes';
import theme from '@Base/theme';
import LoadingScreen from '@Components/LoadingScreen';
import SplashScreen from '@Components/SplashScreen';
import { InstallPWAPrompt } from './components/InstallPWAPrompt';

const App: FC = () => {
  const content = useRoutes(routes);
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    (async () => {
      // if (!user) await login();
      setTimeout(() => {
        setInitialized(true);
      }, 1000);
    })();
  }, []);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Suspense
          fallback={
            <div className="loader-container">
              <div className="loader-container-inner">
                <div className="text-center">
                  <LoadingScreen />
                </div>
              </div>
            </div>
          }
        >
          {initialized ? <>
          {content}
            <InstallPWAPrompt/>
          </> : <SplashScreen />}
        </Suspense>
        {/* PWA here */}
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
