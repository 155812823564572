import React, {createContext, useContext, useEffect, useState} from 'react';
import axios from "axios";
import {getLocalStorage, removeLocalStorage, setLocalStorage} from "@Base/services/localStorage.service";
import {LOCAL_STORAGE} from "@Base/constants/constants";
import {getMe, logout} from "@Base/services/api.service";
import {response} from "express";

export const ProvideAuth = ({ children }: { children:  React.ReactNode }) => {
  const auth = useProvideAuth();
  return (
    <authContext.Provider value={auth}>
      {children}
    </authContext.Provider>
  );
}
function useProvideAuth() {
  const [user, setUser] = useState<string>('');

  useEffect(() => {
    getMe().then(response => {
      const userData = getLocalStorage(LOCAL_STORAGE.user)
      setUser(userData?.toString() || '');
    }).catch(err => {
      if (err.response.status === 403) {
        removeLocalStorage(LOCAL_STORAGE.user);
        setUser('')
      }
    })
  },[])

  const signin = (authData: { email: string, password: string, remember: boolean }, callback: (result: boolean) => $TSFixMe) => {
    return axios
      .post(process.env.REACT_APP_API_URL + "/login", authData)
      .then((response) => {
        if (response.data.access_token) {
          setUser(response.data);
          setLocalStorage(LOCAL_STORAGE.user, response.data)
          callback(true);
        }
        callback(false);
        return response.data;
      }).catch(err => {
        callback(false);
      });
  };

  const signout = (callback: (result: boolean) => $TSFixMe) => {
    return logout().then(response => {
      console.log(response, 123123213)
      setUser('');
      removeLocalStorage(LOCAL_STORAGE.user);
      callback(true);
      return response.data;
    }).catch(err => {
      callback(false);
    })
  };

  return {
    user,
    signin,
    signout
  };
}

const authContext = createContext({user: '', signin: (authData: { email: string, password: string, remember: boolean }, cb: $TSFixMe) => {}, signout: (cb: $TSFixMe) => {}});

export const useAuth = () => {
  return useContext(authContext);
}
