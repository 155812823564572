import { FC, ReactNode } from 'react';
import { Box, Typography } from '@mui/material';

import Logo from '@Components/Logo';

interface GeneralLayoutProps {
  title?: string;
  titleComponent?: ReactNode;
  children: ReactNode;
}

const GeneralLayout: FC<GeneralLayoutProps> = ({ title, titleComponent, children }: GeneralLayoutProps) => (
  <Box
    sx={{
      marginTop: 8,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    }}
  >
    <Logo size='large' />

    {
      title &&
      (
        <Typography
            component="h3"
            variant="subtitle1"
            align='center'
        >
          {title}
        </Typography>
      )
    }

    {
      titleComponent &&
      (
        titleComponent
      )
    }

    {children}
  </Box>
);

export default GeneralLayout;
