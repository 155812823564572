import React, { FC, useState } from 'react';
import { IconButton, TextField } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';

type IconPositionType = 'start' | 'end';

interface DatePickerProps {
  value: Date,
  label?: string,
  onChange: (date: Date | null, ) => void,
  iconPosition?: IconPositionType,
  iconComponent?: React.ReactNode,
  backgroundColor?: string,
  formatDate?: string,
  readOnly?: boolean,
}

const DatePickerBase: FC<DatePickerProps> = (props: DatePickerProps) => {
  const { value, label, onChange, iconComponent, backgroundColor, readOnly } = props;
  const iconPosition: IconPositionType = props?.iconPosition || 'start';
  const formatDate: string = props.formatDate || 'dd MMM, yyyy';

  const inputIconComponent: React.ReactNode = iconComponent ?
    (<div onClick={() => setOpen(true && !readOnly)}>{iconComponent}</div>) :
    (<IconButton size='small' onClick={() => setOpen(true && !readOnly)}> <CalendarMonthOutlinedIcon fontSize="inherit" /></IconButton>);

  const [open, setOpen] = useState<boolean>(false);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        open={open}
        label={label}
        value={value}
        readOnly={readOnly}
        inputFormat={formatDate}
        onChange={onChange}
        disableMaskedInput={true}
        onClose={() => setOpen(false)}
        renderInput={(params) => (
          <TextField
            {...params}
            className='none-outline'
            hiddenLabel
            size="small"
            inputProps={{
              ...params.inputProps,
              sx: {
                margin: 0,
                border: 0,
                backgroundColor: backgroundColor || 'background.default'
              }
            }}
            InputProps={{
              ...params.InputProps,
              sx: { width: 170, paddingX: 0 },
              startAdornment: iconPosition === 'start' ? inputIconComponent : undefined,
              endAdornment: iconPosition === 'end' ? inputIconComponent : undefined,
            }}
          />
        )} />
    </LocalizationProvider>
  );
}

export default DatePickerBase;
