import { FC, ReactNode } from 'react';
import {Navigate, useLocation, useNavigate} from 'react-router-dom';
import PrivateLayout from '@Base/layouts/PrivateLayout';
import { useAuth } from '@Base/hooks/useAuth';
import axios from "axios";
import {removeLocalStorage} from "@Base/services/localStorage.service";
import {LOCAL_STORAGE} from "@Base/constants/constants";

interface AuthGuardProps {
  children: ReactNode;
}

type Props = {
  children?: React.ReactNode;
};

const AuthGuard: FC<AuthGuardProps> = (props: Props) => {
  const { children } = props;
  const location = useLocation();
  const auth = useAuth();
  const isAuth = !!auth?.user; // @TODO Need to implement

  if (!isAuth) {
    return (
      <Navigate
        to="/auth/login"
        state={{ blockedResourceUrl: location.pathname }}
        replace
      />
    );
  }

  return <PrivateLayout>{children}</PrivateLayout>;
};

export default AuthGuard;
