import React, { ComponentProps, lazy, Suspense } from 'react';
import type { RouteObject } from 'react-router';

import { ProvideEvent } from '@Base/hooks/useEvent';
import GuestGuard from '@Base/guard/GuestGuard';
import AuthGuard from '@Base/guard/AuthGuard';
import GeneralLayout from '@Base/layouts/PrivateLayout/GeneralLayout';
import BreadcrumbLayout from '@Base/layouts/PrivateLayout/BreadcrumbLayout';
import LoadingScreen from '@Components/LoadingScreen';
import SplashScreen from '@Components/SplashScreen';
import LogoutPage from "@Pages/Auth/Logout";

export const Loadable =
  <Props extends object>(Component: React.FC<Props>) =>
  (props: ComponentProps<typeof Component>) =>
    (
      <Suspense fallback={<LoadingScreen />}>
        <Component {...props} />
      </Suspense>
    );

// Lazy Loaded
// Authentication pages

const LoginPage = Loadable(lazy(() => import('@Pages/Auth/Login')));
const RegisterPage = Loadable(lazy(() => import('@Pages/Auth/Register')));
const ForgotPasswordPage = Loadable(lazy(() => import('@Pages/Auth/ForgotPassword')));

// Pages
const AuthorizationRequiredPage = Loadable(lazy(() => import('@Pages/Errors/AuthorizationRequiredPage')));
const NotFoundPage = Loadable(lazy(() => import('@Pages/Errors/NotFoundPage')));
const ServerErrorPage = Loadable(lazy(() => import('@Pages/Errors/ServerErrorPage')));
const HomePage = Loadable(lazy(() => import('@Pages/Home')));
const ProjectPage = Loadable(lazy(() => import('@Pages/Project')));
const ProjectBlocksPage = Loadable(lazy(() => import('@Base/pages/Project/ProjectDetails/ProjectBlocks')));
const BlockLevelsPage = Loadable(lazy(() => import('@Pages/Project/ProjectDetails/BlockLevels')));
const LevelApartmentsPage = Loadable(lazy(() => import('@Pages/Project/ProjectDetails/LevelApartments')));
const ApartmentItemsPage = Loadable(lazy(() => import('@Pages/Project/ProjectDetails/ApartmentItems')));

const routes: RouteObject[] = [
  {
    path: '',
    children: [
      {
        path: '',
        element: (
          <GuestGuard>
            <SplashScreen />
          </GuestGuard>
        ),
      },
      {
        path: 'auth',
        children: [
          {
            path: 'login',
            element: (
              <GuestGuard>
                <GeneralLayout>
                  <LoginPage />
                </GeneralLayout>
              </GuestGuard>
            )
          },
          {
            path: 'forgot-password',
            element: (
              <GuestGuard>
                <ForgotPasswordPage />
              </GuestGuard>
            )
          },
          {
            path: 'sign-up',
            element: (
              <GuestGuard>
                <RegisterPage />
              </GuestGuard>
            )
          },
          {
            path: 'logout',
            element: (
              <AuthGuard>
                <LogoutPage />
              </AuthGuard>
            )
          },
        ]
      },
    ]
  },
  {
    path: 'home',
    element: (
      <AuthGuard>
        <GeneralLayout title='Home page'>
          <HomePage />
        </GeneralLayout>
      </AuthGuard>
    ),
  },
  {
    path: '/projects',
    children: [
      {
        path: '',
        element: (
          <AuthGuard>
            <GeneralLayout>
              <ProjectPage />
            </GeneralLayout>
          </AuthGuard>
        )
      },
      {
        path: ':projectId',
        children: [
          {
            path: '',
            element: (
              <AuthGuard>
                <BreadcrumbLayout parentRoute={'/projects'}>
                  <ProjectBlocksPage />
                </BreadcrumbLayout>
              </AuthGuard>
            )
          },
          {
            path: "blocks/:blockId",
            children: [
              {
                path: '',
                element: (
                  <AuthGuard>
                    <BreadcrumbLayout parentRoute={'/projects/:projectId'}>
                      <BlockLevelsPage />
                    </BreadcrumbLayout>
                  </AuthGuard>
                ),
              },
              {
                path: "levels/:levelId",
                children: [
                  {
                    path: '',
                    element: (
                      <AuthGuard>
                        <BreadcrumbLayout parentRoute={'/projects/:projectId/blocks/:blockId'}>
                          <LevelApartmentsPage />
                        </BreadcrumbLayout>
                      </AuthGuard>
                    )
                  },
                  {
                    path: 'apartments/:aptId',
                    element: (
                      <AuthGuard>
                        <ProvideEvent>
                          <BreadcrumbLayout
                            parentRoute={'/projects/:projectId/blocks/:blockId/levels/:levelId'}
                            forwardChildComponent={true}
                          >
                            <ApartmentItemsPage />
                          </BreadcrumbLayout>
                        </ProvideEvent>
                      </AuthGuard>
                    )
                  },
                ]
              },
            ]
          },
        ]
      },
    ]
  },
  {
    path: 'about',
    element: (
      <GuestGuard>
        <HomePage />
      </GuestGuard>
    ),
  },
  {
    children: [
      {
        path: '',
        element: (
          <GuestGuard>
            <LoginPage />
          </GuestGuard>
        ),
      },
      {
        path: '401',
        element: <AuthorizationRequiredPage />,
      },
      {
        path: '404',
        element: <NotFoundPage />,
      },
      {
        path: '500',
        element: <ServerErrorPage />,
      },
      {
        path: '*',
        element: <NotFoundPage />,
      },
    ],
  },
];

export default routes;
