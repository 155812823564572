import { FC } from 'react';
import { useNavigate, useLocation, useParams, Params } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';

import { useEvent } from '@Base/hooks/useEvent';
import LinkButton from '@Components/Buttons/LinkButton';

interface BreadcrumbNavigationProps {
  parentRoute?: string;
  forwardChildComponent?: boolean;
}

const BreadcrumbNavigation: FC<BreadcrumbNavigationProps> = (props: BreadcrumbNavigationProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const emitEvent = useEvent();
  const params: Params = useParams();
  let parentRoute = props.parentRoute || '/projects';
  if (params) {
    const paramIds = Object.keys(params);

    if (parentRoute && paramIds && paramIds.length > 0) {
      paramIds.forEach((paramId: string) => {
        const value: string = params[paramId] || `:${paramId}`;
        if (value) {
          parentRoute = parentRoute.replace(`:${paramId}`, value);
        }
      });
    }
  }

  const onBackPreviousHistory = () => {
    if (props.forwardChildComponent) {
      emitEvent.fireEvent({location, parentRoute});
    } else {
      if (location.state) {
        navigate(-1);
      } else {
        navigate(parentRoute || '/projects', { replace: true });
      }
    }
  }

  return (
    <Box
      sx={{
        marginX: { sm: '-24px' },
        marginBottom: 3,
      }}
    >
      <LinkButton
        label={(<Typography variant='subtitle1' width={'100%'}>Previous</Typography>)}
        iconComponent={(<ArrowBackIosNewRoundedIcon />)}
        iconPosition='start'
        sx={{ paddingLeft: 0 }}
        onClick={onBackPreviousHistory}
        variant='text'
      />
    </Box>
  );
}

export default BreadcrumbNavigation;
