import React, {useEffect} from 'react';
import {useNavigate} from "react-router-dom";

import { useAuth } from '@Base/hooks/useAuth';
import LoadingScreen from "@Components/LoadingScreen";


const LogoutPage = () => {
  const navigate = useNavigate();
  const auth = useAuth();
  useEffect(() => {
    auth.signout((result: boolean) => {
      navigate('/auth/login', { replace: true });
    })
  })

  return (
    <>
      <LoadingScreen></LoadingScreen>
    </>
  );
}

export default LogoutPage;
