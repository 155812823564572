import { FC, ReactNode } from 'react';
import { Box, Container, Link } from '@mui/material';

interface PrivateLayoutProps {
  children: ReactNode;
}

type Props = {
  children?: React.ReactNode;
};

const PrivateLayout: FC<PrivateLayoutProps> = (props: Props) => {
  const { children } = props;
  return (
    <Container id='private-layout' maxWidth="xs">
      <Box
        sx={{
          backgroundColor: 'background.grey50',
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
        }}
      >
        {children}
        <Link href="/auth/logout" mt={2}>Logout</Link>
      </Box>
    </Container>
  );
}

export default PrivateLayout;
