import {FC, Fragment, ReactNode, useEffect, useState} from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';

import BreadcrumbNavigation from '@Components/BreadcrumbNavigation';
import Logo from '@Components/Logo';
import DatePickerBase from '@Components/DatePickerBase';
import {getLocalStorage} from "@Base/services/localStorage.service";
import {LOCAL_STORAGE} from "@Base/constants/constants";

interface BreadcrumbLayoutProps {
  children: ReactNode;
  parentRoute?: string;
  forwardChildComponent?: boolean,
}

interface IUser {
  id: number;
  email: string;
  first_name?: string,
  last_name?: string,
  role: string
}


const BreadcrumbLayout: FC<BreadcrumbLayoutProps> = ({ children, parentRoute, forwardChildComponent }: BreadcrumbLayoutProps) => {
  const [user, setUser] = useState<IUser>()
  useEffect(() =>{
    setUser(getLocalStorage(LOCAL_STORAGE.user).user || null)
  }, [])
  return (
    <Fragment>
      <BreadcrumbNavigation
        parentRoute={parentRoute}
        forwardChildComponent={forwardChildComponent}
      />

      <Container sx={{ mb: 3 }}>
        <Grid container spacing={2} item={true}>
          <Grid xs={5} item={true}>
            <Logo size='medium' />
          </Grid>
          <Grid xs={7} item={true}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                height: '100%'
              }}
            >
              <Typography
                component="h4"
                variant="h4"
                align='left'
                sx={{ paddingLeft: '5px' }}
              >
                {user ? user?.first_name + ' ' + user?.last_name : 'Unknown'}
              </Typography>

              <DatePickerBase value={new Date()} onChange={() => {}} readOnly />
            </Box>
          </Grid>
        </Grid>
      </Container>

      {children}
    </Fragment>
  );
}

export default BreadcrumbLayout;
