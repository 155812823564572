import {getLocalStorage} from "@Base/services/localStorage.service";
import {LOCAL_STORAGE} from "@Base/constants/constants";

export default function authHeaderService() {
  const user = getLocalStorage(LOCAL_STORAGE.user);

  if (user && user.access_token) {
    return { Authorization: 'Bearer ' + user.access_token };
  } else {
    return { Authorization: '' };
  }
}