import React, { createContext, useContext, useState } from 'react';

export const ProvideEvent = ({ children }: { children:  React.ReactNode }) => {
  const event = useProvideEvent();
  return (
    <fireEventContext.Provider value={event}>
      {children}
    </fireEventContext.Provider>
  );
}
function useProvideEvent() {
  const [event, setEvent] = useState<$TSFixMe>('');

  const fireEvent = (event: $TSFixMe) => {
    if(event) {
      setEvent(event);
    } else {
      setEvent('');
    }
  };

  return {
    event,
    fireEvent
  };
}

const fireEventContext = createContext({event: undefined, fireEvent: (event: $TSFixMe) => {}});

export const useEvent = () => {
  return useContext(fireEventContext);
}
