import { createTheme } from '@mui/material/styles';
import { grey as MuiGrey } from '@mui/material/colors';

import ColorConstants from '@Base/constants/colors';

export type PropsColors = 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning' | undefined;
declare module '@mui/material/styles' {
  interface TypographyVariants {
    title: React.CSSProperties;
    heading: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    title?: React.CSSProperties;
    heading?: React.CSSProperties;
  }

  interface TypeBackground {
    grey?: string;
    stroke?: string;
  }

  interface Palette {
    custom: Palette['primary'];
  }
  interface PaletteOptions {
    custom: {};
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    title: true;
    heading: true;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    outlinedExtra: true,
    text: true,
  }
}

const {
  primaryColor,
  secondaryColor,
  strokeColor,
  light,
  neutral,
  grey,
  red,
  green,
  orange,
  blue
} = ColorConstants;

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: primaryColor,
    },
    secondary: {
      main: secondaryColor,
    },
    custom: {
      stroke: strokeColor,
      neutral,
      grey500: grey[500],
    },
    error: {
      "50": red[50],
      "500": red[500],
      "700": red[700],
    },
    success: {
      "50": green[50],
      "500": green[500],
      "700": green[700],
      "800": green[800],
    },
    warning: {
      "50": orange[50],
      "500": orange[500],
      "700": orange[700],
    },
    info: {
      "50": blue[50],
      "500": blue[500],
      "700": blue[700],
      "800": blue[800],
    },
    background: {
      default: MuiGrey[50],
      stroke: strokeColor,
      grey: MuiGrey[100],
    }
  },
  typography: {
    fontFamily: [
      'Montserrat',
      'sans-serif'
    ].join(','),
    fontSize: 14,
    title: {
      color: primaryColor,
      fontWeight: 600,
    },
    button: {
      fontSize: '16px',
    },
    h1: {
      fontSize: 32,
      lineHeight: 1.2,
    },
    h2: {
      fontSize: 28,
      lineHeight: 1.2,
    },
    h3: {
      fontSize: 20,
      lineHeight: 1.4,
    },
    h4: {
      fontSize: 18,
      lineHeight: 1.2,
    },
    h5: {
      fontSize: 16,
      lineHeight: 1.2,
    },
    subtitle1: {
      fontSize: 20,
      color: primaryColor,
      fontWeight: 600,
      lineHeight: 1.4,
    },
    subtitle2: {
      fontSize: 16,
      color: primaryColor,
      fontWeight: 600,
      lineHeight: 1.2,
    },
    body1: {
      fontSize: 16,
      lineHeight: 1.5,
    }
  },
  // Override components
  components: {
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontWeight: 600,
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          height: 27,
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          height: '60px',
          textTransform: 'none',
          '&:hover': {
            borderWidth: '2px'
          }
        }
      },
      variants: [
        {
          props: { variant: 'outlined' },
          style: {
            backgroundColor: light,
          }
        },
        {
          props: { variant: 'outlinedExtra' },
          style: {
            borderWidth: '2px',
            borderStyle: 'solid',
          }
        },
        {
          props: { variant: 'text' },
          style: {
            border: 'none',
            '&:hover': {
              border: 'none'
            }
          }
        },
        {
          props: { variant: 'outlinedExtra', color: 'error' },
          style: {
            borderColor: red[500],
            backgroundColor: red[50],
            ' .MuiTypography-root': {
              color: red[700]
            },
            ' .MuiIconButton-root': {
              color: red[700]
            },
            ':hover': {
              backgroundColor: red[50]
            }
          }
        },
        {
          props: { variant: 'outlinedExtra', color: 'success' },
          style: {
            borderColor: green[500],
            backgroundColor: green[50],
            ' .MuiTypography-root': {
              color: green[800]
            },
            ' .MuiIconButton-root': {
              color: green[800]
            },
            ':hover': {
              backgroundColor: green[50]
            }
          }
        },
        {
          props: { variant: 'outlinedExtra', color: 'info' },
          style: {
            borderColor: blue[500],
            backgroundColor: blue[50],
            ' .MuiTypography-root': {
              color: blue[800]
            },
            ' .MuiIconButton-root': {
              color: blue[800]
            },
            ':hover': {
              backgroundColor: blue[50]
            }
          }
        },
        {
          props: { variant: 'outlinedExtra', color: 'warning' },
          style: {
            borderColor: orange[500],
            backgroundColor: orange[50],
            ' .MuiTypography-root': {
              color: orange[700]
            },
            ' .MuiIconButton-root': {
              color: orange[700]
            },
            ':hover': {
              backgroundColor: orange[50]
            }
          }
        },
        {
          props: { variant: 'contained', color: 'success' },
          style: {
            color: light,
          }
        },
      ]
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          backgroundColor: light,
          borderRight: '1px solid',
          borderColor: strokeColor,
        }
      }
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          h1: 'h1',
          h2: 'h2',
          h3: 'h3',
          h4: 'h4',
          h5: 'h5',
          h6: 'h6',
          subtitle1: 'h3',
          subtitle2: 'h3',
          body1: 'span',
          body2: 'span',
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '&.none-outline fieldset': {
            border: "none"
          }
        }
      }
    },
  }
});

export default theme;
