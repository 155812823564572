import axios from "axios";
import authHeaderService from "./authHeader.service";
import { IItemHistory } from "@Base/mocks/itemHistory";

export const getMe = () => {
  return axios.get(process.env.REACT_APP_API_URL + "/users/me", { headers: authHeaderService() });
};

export const getProjects = () => {
  return axios.get(process.env.REACT_APP_API_URL + "/projects", { headers: authHeaderService() });
};

export const getProjectsByUser = () => {
  return axios.get(process.env.REACT_APP_API_URL + "/projects/by-user", { headers: authHeaderService() });
};

export const getProject = (projectId: string) => {
  return axios.get(process.env.REACT_APP_API_URL + "/projects/" + projectId, { headers: authHeaderService() });
};

export const getBlocks = (projectId: number) => {
  const params = {
    "filter[project_id]": projectId
  }
  return axios.get(process.env.REACT_APP_API_URL + "/blocks", { params: params, headers: authHeaderService() });
};

export const getBlock = (blockId: string) => {
  return axios.get(process.env.REACT_APP_API_URL + "/blocks/" + blockId, { headers: authHeaderService() });
};

export const getLevels = (blockId: number) => {
  const params = {
    "filter[block_id]": blockId
  }
  return axios.get(process.env.REACT_APP_API_URL + "/levels", { params: params, headers: authHeaderService() });
};

export const getLevel = (levelId: string) => {
  return axios.get(process.env.REACT_APP_API_URL + "/levels/" + levelId, { headers: authHeaderService() });
};

export const getApartments = (levelId: number) => {
  const params = {
    "filter[level_id]": levelId
  }
  return axios.get(process.env.REACT_APP_API_URL + "/apartments", { params: params, headers: authHeaderService() });
};

export const getApartment = (apartmentId: string) => {
  return axios.get(process.env.REACT_APP_API_URL + "/apartments/" + apartmentId, { headers: authHeaderService() });
};

export const getItems = (apartmentId: number) => {
  const params = {
    "filter[apartment_id]": apartmentId
  }
  return axios.get(process.env.REACT_APP_API_URL + "/items", { params: params, headers: authHeaderService() });
};

export const getItem = (itemId: string) => {
  return axios.get(process.env.REACT_APP_API_URL + "/items/" + itemId, { headers: authHeaderService() });
};

export const updateItem = (itemId: number, params: object) => {
  return axios.put(process.env.REACT_APP_API_URL + "/items/" + itemId, params, {headers: authHeaderService() });
};

export const logout = () => {
  return axios.get(process.env.REACT_APP_API_URL + "/logout", {headers: authHeaderService() });
};

export const createItemHistory = (historyItem: IItemHistory) => {
  return axios.post(process.env.REACT_APP_API_URL + '/itemHistories', historyItem, {
    headers: authHeaderService(),
  });
};

export const uploadFileToS3 = async (url: string, plain: File) => {
  const config = {
    method: 'put',
    url,
    data : plain,
    headers: {
      'content-type': plain.type
  }
  };
  await axios(config)
}

export type S3URLType = {
  key: string
  url: string
  expireIn: string
}

export const getS3SignedUrl = async (linkCount?: number) => {
  const res = await axios.get(process.env.REACT_APP_API_URL + "/aws/s3-signed-url", {headers: authHeaderService()})
  return res.data.data as S3URLType
}
