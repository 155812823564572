import type { FC, ReactNode } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import PublicLayout from '@Base/layouts/PublicLayout';
import { useAuth } from '@Base/hooks/useAuth';

interface GuestGuardProps {
  children: ReactNode;
}

const GuestGuard: FC<GuestGuardProps> = ({ children }) => {
  const location = useLocation();
  const auth = useAuth();

  const isAuth = !!auth?.user; // @TODO: need to implement

  if (isAuth) {
    const state = location.state as { blockedResourceUrl: string };
    const redirectUrl = state?.blockedResourceUrl || '/projects';
    return <Navigate to={redirectUrl} />;
  } else if (location.pathname === '/') {
    return <Navigate to="/auth/login" />;
  }

  return <PublicLayout>{children}</PublicLayout>;
};

export default GuestGuard;
