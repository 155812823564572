import { CircularProgress, Box } from '@mui/material';

const LoadingScreen = () => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <CircularProgress />
    </Box>
  );
}

export default LoadingScreen;
