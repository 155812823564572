import { FC, ReactNode } from 'react';
import { Box, Container } from '@mui/material';

interface PublicLayoutProps {
  children: ReactNode;
}

const PublicLayout: FC<PublicLayoutProps> = ({ children }) => {
  return (
    <Container id='public-layout' maxWidth="xs">
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
        }}
      >
        {children}
      </Box>
    </Container>
  );
}

export default PublicLayout;
