import { useEffect, useState } from "react";
const webInstallPromptedAt = 'webInstallPromptedAt';

const setInstallPromptSeenToday = (): void => {
  const today = new Date().toISOString();
  localStorage.setItem(webInstallPromptedAt, today);
};

function getUserShouldBePromptedToInstall(daysToWaitBeforePromptingAgain: number) {
  const installPromptLastSeenAt = localStorage.getItem(webInstallPromptedAt) ? localStorage.getItem(webInstallPromptedAt) : null
  if (installPromptLastSeenAt) {
    const lastPrompt = new Date(installPromptLastSeenAt);
    if (lastPrompt.toString() !== "Invalid Date") {
      const daysSinceLastPrompt = (new Date().getTime() - lastPrompt.getTime()) / 86400000
      return daysSinceLastPrompt > daysToWaitBeforePromptingAgain;
    }
  }
  return true
}

const useShouldShowPrompt = (daysToWaitBeforePromptingAgain = 30): [boolean, () => void] => {
  const [userShouldBePromptedToInstall, setUserShouldBePromptedToInstall] = useState(
    getUserShouldBePromptedToInstall(daysToWaitBeforePromptingAgain)
  );

  const handleUserSeeingInstallPrompt = () => {
    setUserShouldBePromptedToInstall(false);
    setInstallPromptSeenToday();
  };

  return [userShouldBePromptedToInstall, handleUserSeeingInstallPrompt];
};

const useWebInstallPrompt = (): [any, () => void, () => void] => {
  const [installPromptEvent, setInstallPromptEvent] = useState<any>();
  const [userShouldBePromptedToInstall, handleUserSeeingInstallPrompt] = useShouldShowPrompt();

  useEffect(() => {
    const beforeInstallPromptHandler = (event: any) => {
      event.preventDefault();
      // check if user has already been asked
      if (userShouldBePromptedToInstall) {
        // store the event for later use
        console.info('Added event');
        setInstallPromptEvent(event);
      }
    };
    window.addEventListener('beforeinstallprompt', beforeInstallPromptHandler);
    return () => window.removeEventListener('beforeinstallprompt', beforeInstallPromptHandler);
  }, [userShouldBePromptedToInstall]);

  const handleInstallDeclined = () => {
    handleUserSeeingInstallPrompt();
    setInstallPromptEvent(null);
  };

  const handleInstallAccepted = () => {
    // show native prompt
    console.info('installPromptEvent', installPromptEvent);
    installPromptEvent.prompt();

    // decide what to do after the user chooses
    installPromptEvent.userChoice.then((choice: any) => {
      // if the user declined, we don't want to show the prompt again
      if (choice.outcome !== 'accepted') {
        handleUserSeeingInstallPrompt();
      }
      setInstallPromptEvent(null);
    });
  };
  return [installPromptEvent, handleInstallDeclined, handleInstallAccepted];
};
export default useWebInstallPrompt;