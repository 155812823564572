import { Box } from '@mui/material';
import Logo from '@Components/Logo';

const SplashScreen = () => {
  return (
    <Box sx={{ display: 'flex', height: '100vh', justifyContent: 'center', alignItems: 'center' }}>
      <Logo size='medium' />
    </Box>
  );
}

export default SplashScreen;
