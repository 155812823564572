import { FC } from 'react';
import { Avatar } from '@mui/material';
import LogoImage from '@Assets/logo.png';

export type LogoSizeProps = 'small' | 'medium' | 'large' | 'flexible';
export const LogoSizeConfigs: { [x: string]: { width: number | string, height: number | string } } = {
  small: {
    width: 30,
    height: 30,
  },
  medium: {
    width: 97,
    height: 97,
  },
  large: {
    width: 140,
    height: 140,
  },
  flexible: {
    width: '100%',
    height: 'auto'
  }
};

interface Props {
  size: LogoSizeProps
}
const Logo: FC<Props> = ({ size }) => {
  return (
    <Avatar
      sx={{ m: 1, mb: 3, bgcolor: 'transparent', width: LogoSizeConfigs[size].width, height: LogoSizeConfigs[size].width }}
      alt="P&L Group Logo"
      src={LogoImage}
    >
      P&L Group
    </Avatar>
  );
}

export default Logo;
