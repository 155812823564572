const ColorConstants = {
  primaryColor: '#4F5972',
  secondaryColor: '#19857b',
  strokeColor: '#D9DBE9',
  neutral: '#FAFAFA',
  light: '#FFFFFF',
  grey: {
    500: '#667085',
  },
  red: {
    50: '#FEF3F2',
    500: '#F04438',
    700: '#B42318',
  },
  green: {
    50: '#ECFDF3',
    500: '#12B76A',
    700: '#027A48',
    800: '#05603A',
  },
  orange: {
    50: '#FFFAEB',
    500: '#F79009',
    700: '#B54708',
  },
  blue: {
    50: '#64b5f6',
    500: '#2196f3',
    700: '#1976d2',
    800: '#1565c0',
  },
};

export default ColorConstants;
